body {
    font-family: "Open Sans", sans-serif !important;
}

.white {
    color: #fff !important;
}

select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
    -webkit-appearance: none;
    background-position-x: 95%;
    background-size: 12px;
}

.mobileAddress {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 12px;
}

.addresDesktopInner {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.addresDesktop {
    justify-content: space-between;
    box-shadow: 0px 0px 5px 0px;
    margin-right: 20px;
    border-radius: 12px;
}

.addresDesktopInner {
    display: flex;
    align-items: center;
    background-color: #ffc000;
}

.header {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 30%);
    height: 90px;
    display: flex;
    align-items: center;
}

.menuLeft>span {
    cursor: pointer;
    margin-right: 24px;
    font-size: 18px;
    font-weight: 600;
    color: #252523;
    padding-bottom: 5px;
}

.menuLeft>a {
    text-decoration: none;
}

.menuLeft>a>span {
    cursor: pointer;
    margin-right: 24px;
    font-size: 18px;
    font-weight: 600;
    color: #252523;
    padding-bottom: 5px;
}

.menuLeft>a>span:hover {
    box-shadow: 0px -5px 0px #ffc000 inset;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    scroll-margin-bottom: 8px;
}

.menuLeft>span:hover {
    box-shadow: 0px -5px 0px #ffc000 inset;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    scroll-margin-bottom: 8px;
}

.dropDown {
    cursor: pointer;
}

.menuLeft {
    position: relative;
    display: flex;
    align-items: center;
}

.dropdown-content>li:hover::before {
    background: #ffc000;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    padding: 0;
}

.dropdown-content>li:hover {
    background: #f7f7f7 !important;
}

.menuRight {
    margin-left: auto;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.dropdown-content li {
    list-style: none;
    position: relative;
    color: #252523 !important;
    width: 228px;
    padding: 16px 24px;
    border-bottom: 1px solid #e3e3e3 !important;
    font-size: 14px;
    padding-top: 13px;
    padding-bottom: 15px;
    cursor: pointer;
}

.dropdown-content a li {
    color: black;
    padding: 5px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a li:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropbtn {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    color: #252523;
}

.menuRight {
    display: flex;
    align-items: center;
}

.location {
    cursor: pointer;
    margin: auto 24px auto 5px;
    display: flex;
    padding: 8px 0 8px 0;
}

.locationInner>div {
    flex: 0 0 auto;
    color: #ffc000;
    display: flex;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    font-stretch: normal;
    margin-bottom: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.loc {
    color: #131313 !important;
    font-size: 14px !important;
}

.signIn {
    cursor: pointer;
    display: flex;
    padding: 0 10px;
    align-self: stretch;
    box-shadow: 0px 0 6px 0 rgb(0 0 0 / 30%);
    border-radius: 0;
    align-items: center;
    color: #000;
    text-decoration: none;
    position: relative;
}

.signIn>img {
    width: 35px;
    height: 35px;
}

.signIn>label {
    font-size: 18px;
    font-weight: 600;
    color: #252523;
    margin-left: 10px;
}

.location:hover {
    box-shadow: 0px -5px 0px #ffc000 inset;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    scroll-margin-bottom: 8px;
}

.credit {
    margin-left: 20px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    position: relative;
}

.carts {
    position: absolute;
    top: -7px;
    left: 2px;
    font-size: 11px;
    background: #ffc000;
    height: 20px;
    width: 20px;
    text-align: center;
    display: flex;
    border-radius: 63px;
    font-size: 12px !important;
    align-items: center;
    justify-content: center;
}

.credit:hover {
    box-shadow: 0px -5px 0px #ffc000 inset;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    scroll-margin-bottom: 8px;
}

.credit>span {
    color: #131313;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-left: 8px;
    font-stretch: normal;
    letter-spacing: normal;
    font-weight: 600;
}

.dropdown {
    top: -2px;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1200px !important;
}

.dropdown-content a:hover {
    background-color: transparent !important;
    color: #000 !important;
}

/* pizzamaker */
.tabsBorder {
    border: 1px solid #cfcecc;
    border-top: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    text-decoration: underline;
    background-color: #ffffff;
    outline: 0;
    border-bottom: 1px solid #ffffff;
    box-shadow: inset 0 5px 0 -1px #ffc000 !important;
    border-top: none;
}

.nav-item {
    border-top: 1px solid #cfcecc;
}

.crust {
    margin: 0 0 15px 0;
    padding: 15px;
    background: #f1f1f1;
    text-transform: capitalize;
    line-height: 25px;
    font-size: 21px;
}

.nav-tabs .nav-link {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom: 0px !important;
}

.crustPreview {
    cursor: pointer;
    width: 100px;
    height: 50px;
    overflow: hidden;
    border-bottom: 3px solid #f1f1f1;
    padding: 0 8px;
    position: relative;
}

.crustPreview>img {
    width: 100%;
}

.activeCrust {
    position: relative;
    border-bottom: 3px solid #ffc000;
}

.crustDesc {
    padding: 0 10px;
    text-align: center;
}

.crustDesc>span {
    font-size: 14px;
}

.crustDesc>svg {
    float: right;
    margin-top: 5px;
}

.sizes {
    border: 1px solid #cfcecc !important;
    padding: 5px 0px;
    border-radius: 9px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    position: relative;
    user-select: none;
}

.sizes>p {
    font-size: 14px;
}

.sizesSauce {
    border: 1px solid #cfcecc !important;
    padding: 10px 0px;
    border-radius: 9px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
}

.sizesSauce>p {
    font-size: 14px;
}

.nav-item .nav-link {
    color: #000;
    cursor: pointer;
}

.peproni {
    cursor: pointer;
}

.left {
    clip: rect(0px, 253px, 487px, 0px);
}

.right {
    clip: rect(0px, 484px, 469px, 254px);
}

.modal-header {
    border: none;
}

.topActive {
    border: 1px solid #ffc000;
    border-radius: 10px;
    position: relative;
}

.circleTop {
    position: absolute;
    top: 5px;
    left: 12%;
    color: #ffc000;
    fill: #fff;
}

/* Home */
.featured {
    align-items: baseline;
}

.featured>h2 {
    font-weight: 600;
}

.featured>span {
    font-weight: 600;
    font-size: 12px;
    margin-left: 10px;
}

.Pizzas h2 {
    font-size: 16px;
}

.Pizzas {
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.featured>span {
    color: #ffc000;
}

hr {
    opacity: 1 !important;
    color: #ffc000 !important;
}

.MenuInner {
    display: flex;
    border: 1px solid #cfcecc;
    border-radius: 5px;
    padding: 15px;
    min-height: 250px;
    position: relative;
}

.ProductImg {
    max-width: 135px;
    max-height: 135px;
    border-radius: 4px;
}

.productImgDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productImgDiv>svg {
    margin-left: auto;
}

.productImgDiv>p {
    font-size: 14px;
    padding: 0px 45px;
    text-align: center;
    margin: 10px 0 0 0;
}

.MenuInner label {
    font-weight: 600;
}

.MenuInner span {
    font-weight: 600;
}

.form-control {
    border-radius: 35px !important;
}

.form-control:focus {
    border: 1px solid #ced4da;
    box-shadow: none !important;
}

.productSelect {
    font-size: 14px !important;
}

.productConfirm>select {
    width: 50px !important;
    padding: 0px !important;
    text-align: center !important;
}

.productConfirm {
    display: flex;
    position: absolute;
    bottom: 15px;
    width: 60%;
}

.productConfirm>span {
    display: block;
    width: 100%;
    color: #fff;
    background-color: #ffc000;
    border-color: #ffc000;
    padding: 10px 0;
    text-align: center;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.productDes {
    width: 96%;
    height: 100%;
    position: absolute;
    background-color: #fff;
}

.fade-in-image {
    border: 1px solid #cfcecc;
    padding: 15px;
    background-color: #fff;
    z-index: 1;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.footer {
    margin-top: 100px !important;
    font-size: 14px;
}

.footerMenu>label {
    font-weight: 600;
}

.footerMenu>ul {
    padding: 0px;
}

.footerMenu>ul>li {
    list-style: none;
}

.footerMenu>ul>li:hover {
    border-bottom: 1px solid #ffc000;
    display: inline;
}

.footerMenu>ul>a>li:hover {
    border-bottom: 1px solid #ffc000;
    display: inline;
}

.footerMenu>ul>li>a {
    text-decoration: none;
    line-height: 25px;
}

.footerPages>span {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #ffc000;
    font-size: 14px;
}

.Btn {
    border: 1px solid #ffc000;
    background: #ffc000;
    padding: 10px 15px;
    border-radius: 30px;
    width: 100%;
    color: #fff;
}

.registerForm>label {
    font-size: 14px;
    margin-bottom: 10px;
}

.nav-left {
    float: left;
    margin-right: 1rem;
    display: table !important;
}

.nav-left .nav-item.show .nav-link,
.nav-left .nav-link.active {
    box-shadow: inset -4px 0px 0 -1px #ffc000 !important;
    border: none !important;
}

.nav-left .nav-item {
    border: none !important;
}

.prevOrder {
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.prevOrderHeader {
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.sr-only {
    display: none;
}

.ingredients {
    padding: 10px;
    background: #ffc000;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}

.ingredients>span {
    color: #fff;
    font-size: 14px;
}

.ingredientsCheck {
    position: absolute;
    right: -10px;
    color: #ffc000;
    top: -15px;
}

.chips {
    background: #ffc000;
    font-size: 12px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
    user-select: none;
    margin-right: 15px;
}

.chipsDeleted {
    background: red;
    font-size: 12px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
    user-select: none;
    margin-right: 15px;
}

.sides {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ffc000;
    border-radius: 7px;
    margin-right: 15px;
    cursor: pointer;
    user-select: none;
    margin-top: 10px;
    max-width: 100px;
    position: relative;
}

.sides>span {
    font-weight: 400;
    font-size: 10px;
}

.sides>svg {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 10px;
    position: absolute;
    top: -3px;
    right: 5px;
    font-size: 10px;
    width: 20px;
}

.modal-dialog {
    max-width: 500px !important;
}

.defaultBtn {
    background-color: #ffc000 !important;
    border-color: #ffc000 !important;
    margin-right: 10px;
}

.modal-content {
    overflow: auto;
}

.modal-header {
    border: none !important;
}

.customModal h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.customModal span {
    font-size: 14px;
    font-weight: 600;
}

.customModal {
    border-bottom: 1px solid #ffc000;
}

.modal-title {
    width: 100%;
}

.ings>li {
    list-style: none;
    width: 50%;
}

.ings {
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
}

.quantity button {
    border: 1px solid #ffc000;
    background-color: transparent;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.quantity input {
    width: 77%;
    text-align: center;
    border: none;
    vertical-align: super;
    margin-right: 5px;
    margin-left: 5px;
}

.quantity input:focus-visible {
    outline: none;
}

.quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border-top: 1px solid #ffc000;
    border-bottom: 1px solid #ffc000;
    padding: 5px 0px;
}

.cartBtn {
    padding: 10px;
    background-color: #ffc000;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 20px;
}

.cartBtn span {
    color: #fff;
}

.customModal img {
    width: 150px;
}

.ings .d-block {
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 5px;
}

.productModal .modal-content {
    height: auto;
}

.productInnerHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.cartProducts {
    margin-bottom: 20px;
    border-bottom: 1px solid silver;
    padding-bottom: 15px;
}

.cartAddBtn {
    border: 1px solid;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    color: #ffc000;
    cursor: pointer;
}

.cartInput {
    width: 70%;
    padding: 5px;
    border: 1px solid #bcbcbc;
    border-radius: 8px;
}

.cartApply {
    border: 1px solid;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    color: #ffc000;
    width: 25%;
    cursor: pointer;
}

.cartOrder {
    border: 1px solid #ffc000;
    background-color: #ffc000;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    margin-top: 10px;
    font-weight: bold;
    cursor: pointer;
}

.toppings {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
}

.toppBtn {
    border: 1px solid #ffc000 !important;
    color: #ffc000 !important;
}

.toppActive {
    background-color: #ffc000 !important;
    color: #fff !important;
}

.pizzaInput {
    width: 12%;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border: 1px solid silver;
    position: absolute;
    height: 44px;
    border-right: 0px;
    padding-left: 8px;
    font-size: 14px;
}

.pizzaBtn {
    display: block;
    width: 88%;
    color: #fff;
    background-color: #ffc000;
    border-color: #ffc000;
    padding: 10px 0;
    text-align: center;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
}

select:focus-visible {
    outline-offset: 0px !important;
    outline: 0px !important;
}

.peproni {
    border-top: 1px solid silver;
    border-bottom: 1px solid silver;
}

.toppingsSize {
    font-size: 14px;
    padding: 10px;
    background: #fff;
    border: 1px solid silver;
    border-radius: 44%;
}

.opened {
    display: flex;
}

.closed {
    display: none !important;
}

.selected {
    border: 1px solid #ffc000;
}

.nav-item {
    width: 33%;
}

.nav-item button {
    width: 100%;
}

.btn {
    padding: 5px !important;
    font-size: 14px !important;
    margin-top: 5px;
}

.signIn .dropdown {
    top: 0px;
}

.dropdown-item {
    font-size: 14px;
}

.customDropdown {
    display: flex;
    flex-direction: column;
    background: #fff;
    position: absolute;
    top: 75px;
    width: 100%;
    left: 0;
    border: 1px solid silver;
    border-radius: 5px;
}

.customDropdown span:hover {
    background-color: #ffc000;
    color: #fff;
}

.customDropdown span {
    padding: 5px;
    font-size: 14px;
    line-height: 35px;
    user-select: none;
}

.customDropdown a:hover {
    background-color: #ffc000;
    color: #fff;
}

.customDropdown a {
    padding: 5px;
    font-size: 14px;
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid silver;
    line-height: 35px;
    user-select: none;
}

.orders {
    border: 1px solid #ffc000;
    padding: 5px;
    border-radius: 10px;
}

.item {
    display: inline-block;
}

.item+.item::before {
    display: inline-block;
    white-space: pre;
    content: ", ";
}

.customPizzaBtn {
    position: absolute;
    bottom: 15px;
    width: 60%;
}

.dropdown2 {
    position: relative;
    display: inline-block;
}

.dropdown-content2 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown2:hover .dropdown-content2 {
    display: block;
}

.dropdown-content2 a {
    text-decoration: none;
}

.dropdown-content2 li {
    list-style: none;
    position: relative;
    color: #252523 !important;
    width: 228px;
    padding: 16px 24px;
    border-bottom: 1px solid #e3e3e3 !important;
    font-size: 14px;
    padding-top: 13px;
    padding-bottom: 15px;
    cursor: pointer;
}

.dropdown-content2 li:hover {
    background-color: #ddd;
}

.stromboliInput {
    width: 20%;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border: 1px solid silver;
    position: absolute;
    height: 44px;
    border-right: 0px;
    padding-left: 8px;
    font-size: 14px;
}

.porsition2 {
    position: absolute;
    z-index: 1;
    left: -5px;
    width: 80%;
    top: 25px;
    left: 40px;
}

.card-img-top {
    height: 220px;
    object-fit: cover;
}

.extraImg {
    width: 80%;
    top: 40px;
    left: 44px;
}

.heroImg {
    height: 520px;
    object-fit: cover;
}

.mobileFooter {
    display: none !important;
}

.deals {
    border-bottom: none !important;
    display: flex;
    justify-content: center;
}

.dealsIng li {
    width: 50%;
}

.peproni {
    position: relative;
}

.dealSize {
    cursor: auto !important;
}

.dealCart {
    display: block;
    padding: 15px;
    background: #ffc000;
    float: right;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
}

.dealProducts {
    padding-bottom: 0px;
    border: none;
}

.friedChicken {
    position: relative !important;
    bottom: 0px !important;
}

.activeItem {
    background-color: #cfcecc;
}

.toppingsSize {
    user-select: none;
}

.wings {
    height: 280px;
}

.cart-item>div {
    border-bottom: 1px solid silver;
    line-height: 40px;
    user-select: none;
}

textarea {
    resize: none !important;
}

.whole:nth-child(1) b {
    display: inline-block;
}

.whole b {
    display: none;
}

.left:nth-child(1) b {
    display: inline-block;
}

.left b {
    display: none;
}

.right:nth-child(1) b {
    display: inline-block;
}

.right b {
    display: none;
}

.dealDetails {
    max-width: 80%;
}

.StripeElement {
    margin: 15px auto;
    padding: 10px 12px;
    color: #32325d;
    background-color: white;
    border: 2px solid transparent;
    border-radius: 4px;
    border: 2px solid #ffc000;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.product {
    width: 100%;
    max-width: 450px;
    margin: auto;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    overflow: hidden;
}

.btn-pay {
    display: block;
    width: 100%;
    border: none;
    background: #ffc000;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 10px;
}

.checkout-card {
    border: 1px solid silver;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    cursor: pointer;
}

.checkout-card:hover {
    background-color: #ffc000;
    border-color: #ffc000;
    color: #fff;
}

.active-card {
    background-color: #ffc000;
    border-color: #ffc000;
    color: #fff;
}

.checkout-check {
    display: flex;
    align-items: center;
}

.checkout-check>input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.date-check {
    height: 50px;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
}

.date-col-2 {
    justify-content: space-around;
    display: flex;
}

.date-col-2>select {
    height: 50px;
    padding: 10px;
    border-radius: 10px;
    width: 25%;
}

.cards {
    border: 1px solid #ffc000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
}

.mobileQuantity {
    position: fixed;
    top: 0;
    width: 90% !important;
    background: #fff;
    padding: 15px;
    z-index: 1;
}

.overLayCustom {
    position: absolute;
    width: 100%;
    height: 200vh;
    background-color: #0000006e;
    z-index: 1;
}

.addressMenu {
    position: absolute;
    right: 0;
    background: #fff;
    width: 30%;
    z-index: 2;
    top: 0;
    height: 200vh;
}

.option {
    text-align: center;
    font-size: 14px;
    border: 1px solid silver;
    padding: 5px;
    border-radius: 9px;
    background: #fff;
    width: 48%;
    cursor: pointer;
}

.active-option {
    border-color: #ffc000;
}

/* Responsive */
/* Mobile */
@media screen and (max-width: 1000px) and (min-width: 319px) {
    .addressMenu {
        width: 100%;
    }

    .row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .mobileTopping {
        text-align: center;
        margin-top: 10px !important;
    }

    .mobileFooter {
        display: block !important;
        margin-top: 40px !important;
    }

    .menuLeft>span {
        font-size: 12px;
        margin-right: 12px;
        margin-top: 4px;
    }

    .dropbtn {
        font-size: 12px;
    }

    .menuLeft img {
        margin-right: 10px !important;
    }

    .signIn label {
        font-size: 12px;
    }

    .heroImg {
        height: 300px;
    }

    .respFeature {
        margin-top: 10px !important;
    }

    .respPizza {
        margin-top: 10px !important;
    }

    .dropdown-content {
        width: 100%;
    }

    .dropdown-content li {
        width: 100%;
    }

    .nav-tabs .nav-link {
        font-size: 12px;
    }

    .MenuInner {
        flex-direction: column-reverse;
        height: auto;
    }

    .customPizzaBtn {
        position: relative;
        width: 100%;
    }

    .mobileHide {
        display: none;
    }

    .quantity button {
        border: 1px solid #ffc000;
        background-color: transparent;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .quantity {
        width: 50%;
    }

    .respIng {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .cartProducts {
        text-align: center;
    }

    .quantity {
        width: 100%;
    }

    .respCustimBtn {
        margin-top: 2.5em !important;
    }

    .productDes {
        flex-direction: column-reverse;
    }

    .productConfirm {
        position: relative;
        width: 100%;
        bottom: auto;
    }

    .productDes img {
        width: auto !important;
        height: auto !important;
        max-width: 225px;
        max-height: 225px;
    }

    .dropdown2 {
        font-size: 12px;
    }

    .footer {
        display: none !important;
    }

    .pizzaBtn {
        width: 100%;
    }

    .pizzaInput {
        width: 15%;
    }
}